// --------------------
// VARIABLES
//---------------------

// Colors
$body-color		: #000A28;
$primary        : #F0D957; // yellow
$secondary      : #333;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;

// --------------------
// Container Options
//---------------------

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1170px,
);
  
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px,
);
