.section-usps{
	background: linear-gradient(273.06deg, #404551 -14.24%, rgba(69, 76, 87, 0) 126.28%), #090B0F;

	.usps{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 1020px;
		margin: 0 auto;

		&__item{
			width: 50%;
			margin: 0 0 3rem;

			&:nth-child(3),
			&:nth-child(4){
				margin: 0;
			}
		}

		@include media-breakpoint-up(lg){
			&__item{
				margin: 0;
				width: auto;
			}
		}

		img{
			width: 55px;
			display: block;
			margin: 0 auto 1rem;

			@include media-breakpoint-up(md){
				width: 87px;
			}
		}

		p{
			color:#FFF;
			font-weight: 500;
			font-size: 1.6rem;
			line-height: 127.5%;
			text-transform: capitalize;
			text-align: center;
			color: #FFFFFF;
			margin: 0;

			@include media-breakpoint-up(md){
				font-size: 2.2rem;
			}
		}
	}
}