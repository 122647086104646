.section-testimonials{
	overflow: visible;
	z-index: 3;
	position: relative;

	.fivestars{
		margin: 0 auto 2rem;
	}
	
	.testimonials{
		margin: 1rem -13px 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		z-index: 4;
		position: relative;

		@include media-breakpoint-up(md){
			margin: 2rem -13px 0;
		}

		.t-col{
			width: 100%;
			padding: 0 13px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			@include media-breakpoint-up(lg){
				width: 50%;
			}
		}

		.t-item{
			margin: 0 0 10px;
			filter: drop-shadow(0px 21px 50px rgba(0, 0, 0, 0.15));
			padding: 10px;
			background: #FFF;

			@include media-breakpoint-up(md){
				margin: 0 0 20px;
			}

			img{
				width: 100%;
			}
		}
	}

	.img-pattern{
		position: absolute;
		bottom:-19%;
		right:0;
		height: auto;
		display: none;

		@include media-breakpoint-up(md){
			width: 180px;
			bottom:-10%;
			display: block;
		}

		@include media-breakpoint-up(xl){
			width: 281px;
			bottom:-19%;
		}
	}

	.btn-section{
		margin: 2rem auto 0;
	}
}