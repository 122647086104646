.section-form{
	background: #F5F5F5;
	padding-bottom:0;

	@include media-breakpoint-up(xl){
		padding: 100px 0 0;
	}

	h2{
		max-width: 800px;
		margin: 0 auto;

		@include media-breakpoint-down(md){
			font-size: 4.5rem;
		}

		&:after{
			margin-bottom: 0;
		}
	}

	.container{
		position: relative;
		z-index: 2;
	}

	.footer-img{
		position:relative;
		z-index: 1;
	    margin-top: -14vw;
	}

	.eyebrow{
		color:#000A28;
		margin: 0 0 1rem;

		@include media-breakpoint-down(md){
			font-size: 1.6rem;
			letter-spacing: .05em;
		}
	}

	.starterpack{
		position:relative;
		margin: 0 auto 2rem;
	    max-width: none;
	    width: calc(121vw);
	    left: -3.3vw;

		@include media-breakpoint-up(sm){
			width: 631px;
			max-width:100%;
			left: 0px;
		}

		@include media-breakpoint-up(md){
			left: 50px;
		}
	}

	.form-col{
		p,form{
			max-width: 530px;
			margin-left: auto;
			margin-right: auto;
		}

		p{
			opacity: .8;
		}

		form{
			margin: 3rem auto 0;
		}

		button{
		}

		.btn-section{
			margin: 3rem auto 0;
			max-width: 100%;
			text-align: center;

	        @include media-breakpoint-up(md) {
				margin: 5rem auto 0;
	        }
		}
	}
}