.section-copy-image{
	overflow: visible;

	.image-col{
		position: relative;

		img{
			position: relative;
			z-index: 2;

			@include media-breakpoint-down(md){
				max-width: 600px;
				margin-left: auto;
				margin-right: auto;
				display: block;
			}
		}
	}

	&#section-2{
		padding-bottom: 0;

		.image-col{
			padding: 0;
		}
		
		@include media-breakpoint-up(sm){
			.image-col{
				padding: 0 15px;
			}
		}

		@include media-breakpoint-up(xl){
			padding: 70px 0 0;
			.copy-col{
				padding-left: 57px;
			}

			img{
				width: 692px;
				max-width: none;
				position: relative;
				left: -125px;
				bottom: -40px;
			}
		}
	}

	&#section-3{
		padding-top: 0px;
		overflow: hidden;

		.image-col{
			padding: 0;
			.img-pattern{
				right: 15px;
				top: -90px;
			}
		}
		
		@include media-breakpoint-up(sm){
			.image-col{
				padding: 0 15px;
			}
		}

		@include media-breakpoint-up(lg){
			padding-top: 40px;
		}

		@include media-breakpoint-up(xl){
			padding-bottom: 65px;

			.copy-col{
				padding-right: 57px;
			}

			img{
				width: 710px;
				max-width: none;
				position: relative;
				left: -45px;
				bottom: 20px;
			}
		}
	}


	&#section-8{
		overflow: hidden;

		@include media-breakpoint-down(lg){
			.image-incontent{
				max-width: 600px;
				width: 100%;
			}
		}

		@include media-breakpoint-up(xl){
			.copy-col{
				padding-right: 50px;
			}

			.image-col{
				img{
					width: 645px;
					max-width: none;
					position: relative;
					left: -20px;
					bottom: 35px;
				}
			}
		}
	}

	.btn-section{
		margin: 3.5rem auto 0;
		@include media-breakpoint-up(lg){
			margin: 3.5rem 0 0;
		}
	}
}