.section-awards{

	.awards{
		background: #FFF;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 25px 15px;
		box-shadow: 0px -42px 50px rgba(0, 0, 0, 0.03);
		margin: 1rem 0 0;

		@include media-breakpoint-up(md){
			padding: 60px 80px;
		}

		@include media-breakpoint-up(xl){
			padding: 80px 125px;
		}

		img{
			width: 100%;
		}
	}

	&__bg{
		position: absolute;
		bottom:0;
		left:0;
		height: 54%;
		width: 100%;
		z-index: 0;
		background: linear-gradient(273.06deg, #404551 -14.24%, rgba(69, 76, 87, 0) 126.28%), #090B0F;

		img{
			width: 532px;
			max-width: 50%;
			position: absolute;
			bottom:0;
			left:0;

			@include media-breakpoint-up(md){
				max-width: 100%;
			}
		}
	}

	.container{
		position: relative;
		z-index: 1;
	}

	.btn-section{
		margin: 3.5rem auto 0;

		p{
			color:#FFF;
		}
	}
}