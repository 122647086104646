// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
button[type=submit],
input[type=button] {
    width: 100%;
    border: 0;
    border-radius: 0;
    margin-bottom: 12px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: -.02em;
    padding: 18px 10px;
    white-space: normal;
    background: none;
    color: $body-color;
    position: relative;
    font-family: $headings-font;
    font-weight: 700;
    font-size: 2.2rem;
    appearance:none;
    position: relative;

    @include media-breakpoint-up(sm) {
        font-size: 2.3rem;
        padding: 22px 20px;
    }

    @include media-breakpoint-up(md) {
        font-size: 2.65rem;
        padding: 27px 20px;
    }

    &:after{
        content:'';
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background: $primary;
        transition: background-color .4s ease;
        transform: skewY(-7deg) rotate(7deg);
        z-index: 1;
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;
        border:none;
        color: $body-color;
        background: none !important;
        box-shadow: none !important;

        &:after{
            background: rgba($primary, .8);
        }
    }

    &:after{
        z-index: 0;
    }
    span{
        position:relative;
        z-index: 2;
    }
}

.btn-section {
    max-width: 100%;

    @include media-breakpoint-up(md) {
        max-width: 472px;
    }
    
    p {
        opacity: .8;
        width: 90%;
        margin: 0 auto;
        font-size: 12px;
        line-height: (17/12);
    }
}