form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        padding: 0 15px;
        padding-left: 36px;
        border: none;
        margin-bottom: 0;
        border-radius: 0px;
        font-size: 1.4rem;
        color:rgba(#000A28,.8);
        line-height: 1;
        background: #FFFFFF;
        border: 0.5px solid rgba(0, 0, 0, 0.15);
        height: 54px;
        box-shadow: none;

        @include media-breakpoint-up(md) {
            height: 76px;
            font-size: 1.7rem;
            padding: 0 15px;
            padding-left: 55px;
        }

        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }
        &::placeholder{
            color:rgba(#000A28,.8) !important;
            opacity: 1 !important;
        }
    }

    textarea {
        height: 130px !important;
        padding-top: 20px;
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            height: 170px !important;
            padding-top: 28px;
            padding-bottom: 28px;
        }
    }

    select {
        appearance: none;
    }

    .form-group {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        line-height: 1;
        display: block;

        @include media-breakpoint-up(md) {
            margin-bottom: 15px;
        }

        &:before{
            content:'';
            width: 13px;
            height: 13px;
            display: block;
            position: absolute;
            left: 15px;
            top: 22px;

            @include media-breakpoint-up(md) {
                width: 16px;
                height: 16px;
                left: 27px;
                top: 31px;
            }
        }

        &[class*="name"]:before,{
            background: url('../svgs/icon-user.svg') no-repeat center/contain;
        }
        &[class*="email"]:before{
            background: url('../svgs/icon-email.svg') no-repeat center/contain;
        }
        &[class*="phone"]:before{
            background: url('../svgs/icon-phone.svg') no-repeat center/contain;
        }
        &[class*="zip"]:before{
            background: url('../svgs/icon-marker.svg') no-repeat center/contain;
        }
        &[class*="vehicle"]:before{
            background: url('../svgs/icon-car.svg') no-repeat center/contain;
        }
        &[class*="comments"]:before{
            background: url('../svgs/icon-comments.svg') no-repeat center/contain;

            @include media-breakpoint-up(md) {
                top: 30px;
            }
        }
    }

    label.error,
    .field-error {
        display: block;
        margin-bottom: 20px;
        margin: 10px 0 0;
        color: red;
        font-size: 1.5rem;
    }
    .form-messages {
        margin: 15px auto 0;
        text-align: center;
        &.success {
            color: green;
        }
        &.error {
            color: red;
        }
    }
}