.section-hero{
	padding: 120px 0 50px;

    @include media-breakpoint-up(md){
		padding: 180px 0 55px;
    }

    @include media-breakpoint-up(lg){
		padding: 210px 0 55px;
	}

	.eyebrow{
		color:$primary;
		letter-spacing: .225em;
		margin: 0 0 2rem;
	}

	h1{
		margin: 0 0 2.5rem;
	}

	@include media-breakpoint-up(xl){
		h1{
		}
	}

	@include media-breakpoint-down(sm){
		h1,p{
			text-align: center;
		}
	}

	// ATF mobile (iphone size)
	@media screen and (max-width: 480px) and (max-height: 630px){
		padding-top: 110px;

		h1{
			font-size: 3.5rem;
		}

		.eyebrow{
			font-size: 1.4rem;
			margin: 0 0 1.5rem;
		}
	}
	

	// specific desktop request for above the fold
	@media screen and (min-width: 992px){
		@media screen and (max-height: 795px){
			padding: 170px 0 55px;
			
			h1{
				font-size: 4.8rem;
				max-width: 80%;
			}
		
			.col-xl-7{
				flex: 0 0 75%;
				max-width: 75%;
			}
		}
	}

	.subheadline{
		opacity: .8;
		line-height: (23/17);

		@include media-breakpoint-up(xl){
			max-width: 593px;
		}
	}

	.reviews{
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items:center;
		justify-content: space-between;
		margin: 1rem auto 0;
		padding: 0 15px;

		@include media-breakpoint-up(sm){
			padding: 0 40px;
		}

		@include media-breakpoint-up(md){
			justify-content: center;
			margin: 4rem auto 0;
			padding:0;
		}

		&__item{
			width: 50%;
			margin: 20px 0px 0;

			display: flex;
			justify-content: center;
			flex-direction: row;
			align-items:center;

			@media screen and (max-width: 320px){
				padding: 0 5px;
			}

			@include media-breakpoint-up(md){
				margin: 0 10px;
				width: auto;
				max-width: none;
			}

			@include media-breakpoint-up(xl){
				width: 135px;
				margin: 0 25px;
			}

			span{
				font-family: $headings-font;
				color:$primary;
				font-size: 4.5rem;
				line-height: 1;
				margin-right: 10px;

				@include media-breakpoint-up(md){
					font-size: 5rem;
				}
			}
			
			img{
				height: 40px;
				max-width: 100%;
				width: auto;
			    object-fit: contain;

				@include media-breakpoint-down(md){
					width: 79px;
				}
			}
		}
	}

	.btn-section{
		margin: 3rem 0 0;
	}
}
	