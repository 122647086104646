.section-checklist{
	.checklist{
		max-width: 719px;
		margin: 1rem auto 0;

		&__item{
			background: #F5F5F5;
			padding: 30px 23px;
			position: relative;
			margin: 0 0 17px;

			@include media-breakpoint-up(md){
				padding:30px;
				padding-left:125px;
			}

			h5{
				padding-left: 57px;
				margin: 0 0 10px;
				min-height: 46px;

				@include media-breakpoint-up(md){
					min-height:0;
					padding:0;
					margin: 0 0 5px;
				}
			}

			p{
				line-height: 23px; // 23px regardless of font size
			}

			:last-child{
				margin-bottom: 0;
			}

			&:before{
				content:'';
				background: #090B0F url('../svgs/icon-tick.svg') no-repeat center/contain;
				width: 42px;
				height: 42px;
				position: absolute;
				border-radius: 50%;
				overflow: hidden;
				left: 23px;
				top: 32px;

				@include media-breakpoint-up(md){
					width: 66px;
					height: 66px;
					left: 32px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		&--numbers{
			counter-reset: listcount;
			.checklist__item{
				counter-increment: listcount;

				&:before{
					content: counter(listcount);
					font-family: $headings-font;
					color:$primary;
					font-size: 3rem;
					line-height: 30px;
					text-align: center;
					background-image: none;
					padding-top:5px;

					@include media-breakpoint-up(md){
						font-size: 5rem;
						padding-top:15px;
					}
				}				
			}
		}
	}

	.btn-section{
		margin: 3rem auto 0;
		display: block;
	}
}