// --------------------
// Include Fonts Here
// --------------------
@font-face {
    font-family: 'Bebas';
    src: url('../fonts/Bebas-Regular.woff2') format('woff2'),
    url('../fonts/Bebas-Regular.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../fonts/IBMPlexSans-Regular.woff2') format('woff2'),
    url('../fonts/IBMPlexSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../fonts/IBMPlexSans-Medium.woff2') format('woff2'),
    url('../fonts/IBMPlexSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../fonts/IBMPlexSans-SemiBold.woff2') format('woff2'),
    url('../fonts/IBMPlexSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: url('../fonts/IBMPlexSans-Bold.woff2') format('woff2'),
    url('../fonts/IBMPlexSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

$headings-font: 'Bebas', sans-serif;
$body-font: 'IBM Plex Sans', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 62.5%; // // not changing due to mobile sizing
}

html, body {
    @include font-smoothing('on');
    font-size: 1.6rem; 
    line-height: (23/16);
    font-family: $body-font;
    position: relative;

    @include media-breakpoint-up(sm){
        font-size: 1.7rem; 
        line-height: (23/17);
    }
}

p {
    margin-bottom: 2.1rem;
}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.3;
    margin-bottom: 2rem;
    font-family: $headings-font;
    font-weight: bold;
    text-transform: capitalize;

    br{
        display: none;

        @include media-breakpoint-up(xl){
            display: block;
        }
    }
}

h1, .h1 {
    font-size: 4.2rem; 
    line-height: 1.04;
    letter-spacing: .01em;

    @include media-breakpoint-up(md){
        font-size: 4.8rem; 
    }

    @include media-breakpoint-up(lg){
        font-size: 5.3rem; 
    }
}

h2, .h2 {
    font-size: 3.5rem; 
    line-height: 1;
    letter-spacing: -.02em;

    @include media-breakpoint-up(md){
        font-size: 4rem; 
    }

    @include media-breakpoint-up(lg){
        font-size: 5.3rem; 
    }
    
    &:after{
        content:'';
        height: 6px;
        width: 53px;
        background: $primary;
        display: block;
        margin: 2.5rem 0 2.2rem;
    }
    &.text-center,
    .text-center &{
        &:after{
            margin-left: auto;
            margin-right: auto;
        }
        
        &.text-lg-left{
            @include media-breakpoint-up(lg){
                &:after{
                    margin-left: 0;
                }
            }
        }
    }
}

h3, .h3 {
    font-size: 3rem;  //Laptop: 30px, Ipads: 27px, Mobiles: 24px
}

h4, .h4 {
    font-size: 2.4rem;  //Laptop: 24px, Ipads: 21.6px, Mobiles: 19.2px
}

h5, .h5 {
    font-size: 1.4rem; 
    line-height: 23px; //  23px regardless of font size
    font-family: $body-font;

    @include media-breakpoint-up(sm){
        font-size: 1.8rem; 
    }
}

h6, .h6 {
    font-size: 1.8rem;  //Laptop: 18px, Ipads: 16.2px, Mobiles: 14.4px
}

strong {
	font-family: $headings-font;
}

// Hero Specific Font Sizes
.eyebrow {
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: (22/15);
    font-weight: 600;
    color:$primary;

    @include media-breakpoint-up(md){
        font-size: 2rem;
    }
}
