.section-gallery{
	position: relative;
	z-index: 1;

	h2,p{
		color:#FFF;
	}

	p{
		opacity: .8;
		margin-left: auto;
		margin-right: auto;

		@include media-breakpoint-up(lg){
			max-width: 503px;
		}
	}

	&__bg{
		position: absolute;
		top:0;
		right:0;
		height: 100%;
		width: 100%;
		z-index: 0;
		background: linear-gradient(273.06deg, #404551 -14.24%, rgba(69, 76, 87, 0) 126.28%), #090B0F;

		img{
			width: 411px;
			position: absolute;
			top:0;
			right:0;
		}
	}

	.container,
	&__container{
		position: relative;
		z-index: 1;
	}	


	&__container{
		width: 100%;
		margin: 2rem auto 0;
		max-width: 1367px;

	    @include media-breakpoint-up(lg){
			margin: 4rem auto 0;
	    }

		.gallery{

			&__row{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin: 0 -6px;

				@include media-breakpoint-up(lg){
					margin: 0 -13px;
				}

				@include media-breakpoint-down(lg){
					&:nth-child(1),
					&:nth-child(3){
						.gallery__item{
							width: 50%;

							.gallery__item__aspect{
								padding-bottom: 77%;
							}
						}
					}

					&:nth-child(2){
						.gallery__item{
							&:nth-child(1),
							&:nth-child(6){
								width: 56%;

								.gallery__item__aspect{
									padding-bottom: 54%;
								}
							}
							&:nth-child(2),
							&:nth-child(5){
								width: 44%;

								.gallery__item__aspect{
									padding-bottom: 69.5%;
								}
							}
							&:nth-child(3),
							&:nth-child(4){
								width: 50%;

								.gallery__item__aspect{
									padding-bottom: 54%;
								}
							}
						}
					}
				}

				@include media-breakpoint-up(lg){
					&:nth-child(1),
					&:nth-child(3){
						.gallery__item{
							width: 29%;

							&:nth-child(3){
								width: 42%;

								.gallery__item__aspect{
									padding-bottom: 53%
								}
							}
						}
					}
				
					&:nth-child(2){
						.gallery__col{
							&:nth-child(1){
								width: 71%;

								.gallery__item{
									width: 59%;
											
									&__aspect{
										padding-bottom: 53%
									}

									&:nth-child(2),
									&:nth-child(3),
									&:nth-last-child(2){
										width: 41%;

										.gallery__item__aspect{
											padding-bottom: 78%
										}
									}
								}
							}
							&:nth-child(2){
								width: 29%;

								.gallery__item{
									width: 100%;

									&__aspect{
									    @include media-breakpoint-up(sm){
									    	padding-bottom: 162%;
							   			}
									    @include media-breakpoint-up(lg){
									    	padding-bottom: 166%;
										}
									    @include media-breakpoint-up(xl){
									    	padding-bottom: 163.1%;
									    }
									}
								}
							}
						}
					}
				}
			}

			&__col{
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
			}

			&__item{
				width: 100%;
				padding: 0 6px;
				margin: 0 0 12px;

				@include media-breakpoint-up(lg){
					padding: 0 13px;
					margin: 0 0 26px;
				}

				&__aspect{
					position: relative;
					height: 0;
					width: 100%;
					padding-bottom: 78.5%;
				}

				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					position: absolute;
					top:0;
					left:0;
				}

				@include media-breakpoint-up(lg){
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(2){
						width: 29%;
					}
				}
			}
		}
	}

	.btn-section{
		margin: 3rem auto 0;
	    @include media-breakpoint-up(lg){
			margin: 4rem auto 0;
		}
	}
}