.thank-you{
	position: relative;

	.section-hero{
		min-height: 100vh;
		display: flex;
		flex-direction:row;
		flex-wrap: wrap;
		align-items:center;

		padding: 120px 0;
	
	    @include media-breakpoint-up(md){
			padding: 180px 0;
	    }

	    @include media-breakpoint-up(lg){
			padding: 210px 0;
		}
	}

	footer{
		position: absolute;
		left:0;
		bottom:0;
		width: 100%;
		text-align: center;
		color: #FFF;
	}
}