.section-brands{
	position: relative;
	overflow: visible;
	z-index: 2;

	h2{
		color:#FFF;
	}

	.brands{
		background: #FFF;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 1rem auto 0;
		padding: 15px 15px;
		box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.03);

		picture{
			width:100%;

			img{
				width: 100%;
			}
		}

		@include media-breakpoint-up(md){
			padding: 30px 30px 10px;
			picture{
				width: 50%;
			}
		}
	}

	&__bg{
		position: absolute;
		top:0;
		left:0;
		height: 54%;
		width: 100%;
		z-index: 0;
		background: linear-gradient(273.06deg, rgba(69, 76, 87, 0) -14.24%, #404551 126.28%), #090B0F;

		img{
			width: 532px;
			max-width: 100%;
			position: absolute;
			top:0;
			left:0;
			opacity: .5;

			@include media-breakpoint-up(md){
				opacity: 1;
			}
		}
	}

	.container{
		position: relative;
		z-index: 1;
	}

	.img-pattern{
		position: absolute;
		max-width: 100%;
		left: -184px;
		bottom: -34%;

		@include media-breakpoint-up(lg){
			bottom:-42%;
			left:0;
		}
	}

	.btn-section{
		margin: 2.5rem auto 0;
		@include media-breakpoint-up(md){
			margin: 4.5rem auto 0;
		}
	}
}