footer {
	position: absolute;
	left:0;
	bottom:0;
	width: 100%;
	text-align: center;
	color: #FFF;
	z-index: 5;

	p{
		font-size: 1.3rem;
		text-shadow: 0px 0px 20px rgba(#000,.5);
		@include media-breakpoint-up(md){
			font-size: 1.5rem;
		}
	}
	a{
		color:inherit;
		font-weight: 700;
	}
}