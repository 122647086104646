header {
    padding: 15px 0px;
    background-color: transparent;
    transition: background 0.4s ease, padding .4s ease;

    @include media-breakpoint-up(md){
        padding: 30px 0px;
    }

    &.scrolled {
        box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);
        background: #000;

        @include media-breakpoint-up(md){
            padding: 20px 0px;
        }
    }

    @media screen and (min-width: 1290px){
        .container{
            max-width: 1260px;
        }
    }
}

.header-logo img {
    transition: width 0.4s ease;
    width: 55px;
    // Inverts the logo colour. You could use the filter options to modify a transparent png logo for the different colour header bg.
    // filter: invert(1);

    // Default to small scrolled size on mobile 

    @include media-breakpoint-up(md){
        width: 75px;
    }
    
    @include media-breakpoint-up(lg){
        width: 95px;

        @media screen and (max-height: 795px){
            width: 75px;
        }
    }

    .scrolled & {

        @include media-breakpoint-up(md){
            width: 65px;
        }
    }
}

.header-phone {
    line-height: 1;

    &:hover{
        text-decoration: none;
    } 

    .phone-icon {
        @include media-breakpoint-up(md){
            margin-right: 10px;
        }
        
        img,
        svg {
            max-width: 21px;
            width: 100%;
        }
    }

    // Got questions (if it exists)
    span {
        font-size: 1.6rem; // different font in design
        color:#FFF;   
        display: block;
        line-height: 1;
    }
    // Phone number text
    em {
        font-family: $headings-font;
        font-size: 3.5rem;
        font-weight: 700;
        letter-spacing: -0.02em;
        font-weight: bold;
        font-style: normal;
        color:#FFF;
    }
} 