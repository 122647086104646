.section-beforeafter{
	overflow: visible;
	padding-top:0;

	.ba{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0 -13px;
		position: relative;
		z-index: 2;

		@include media-breakpoint-up(lg){
			margin: 0 -26px;
		}

		&__col{
			width: 100%;
			padding: 0 13px;
			margin: 0 0 25px;

			&:last-child{
				margin:0;
			}

			@include media-breakpoint-up(md){
				width: 50%;
			}

			@include media-breakpoint-up(lg){
				padding: 0 26px;
				margin: 0 0 35px;
			}
		}

		.b-label,
		.a-label{
			font-family: $headings-font;
			font-weight: 700;
			font-size: 1.2rem;
			line-height: 106.5%;
			letter-spacing: -0.02em;
			text-transform: uppercase;
			position: absolute;
			z-index: 2;
			padding: 8px 25px;

			@include media-breakpoint-up(sm){
				padding: 10px 25px;
				font-size: 1.7rem;
			}

			@include media-breakpoint-up(lg){
				padding: 14px 40px;
				font-size: 1.9rem;
			}

			&:after{
				content:'';
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
				transform: skewY(-11deg) rotate(11deg);
				z-index: -1;
			}
		}

		.a-label{
			right:5px;
			bottom:25px;

			&:after{
				background: $primary;
			}

			@include media-breakpoint-up(sm){
				right:10px;
				bottom:30px;
			}

			@include media-breakpoint-up(lg){
				bottom:40px;
			}
		}

		.b-label{
			top:25px;
			left:5px;

			&:after{
				background: #E5E4E3;
			}

			@include media-breakpoint-up(sm){
				left:10px;
				top:30px;
			}

			@include media-breakpoint-up(lg){
				top:40px;
			}
		}

		&__img{
			&__aspect{
				position: relative;
				height: 0;
			}

			img{
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height: 100%;
			}

			&:before{
				content:'';
				width: 70px;
				height: 70px;
				background: url('../svgs/ba-indicator.svg') no-repeat center/contain;
				position: absolute;
				top:50%;
				left:50%;
				transform: translate(-50%,-50%);
				z-index:3;

				@include media-breakpoint-up(md){
					width: 90px;
					height: 90px;
				}

				@include media-breakpoint-up(lg){
					width: 120px;
					height: 120px;
				}
			}
		}

		&__item{
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 10px;
			background: #FFFFFF;
			box-shadow: 0px 3px 50px 5px rgba(0, 0, 0, 0.05);

			@include media-breakpoint-up(md){
				padding: 20px;
			}

			&.vertical{
				.ba__img{
					width: 100%;

					&:not(:last-of-type){
						margin-bottom: 16px;
					}

					&__aspect{
						padding-bottom: 50%;
					}
				}
			}

			&.horizontal{
				.ba__img{
					width: calc(50% - 8px);

					&__aspect{
						padding-bottom: 170%;
					}

					&:before{
						transform: translate(-50%,-50%) rotate(-90deg);
					}
				}
			}
		}
	}


	.img-pattern{
		position: absolute;
		bottom:-52%;
		left:0;
		z-index: 1;
	}
}