.section-guarantee{
	.intro-row{
		img{
			max-width: 246px;
			margin: 0 auto 3rem;
		}
	}

	.copy-col{
		> p, 
		> ol,
		> ol li{
			max-width: 515px;
			margin-left: auto;
			margin-right: auto;
			color:rgba(#FFF,.8);
		}
	}

	ol{
		list-style: none;
		padding:0;
		margin:2rem 0;
		counter-reset: listcount;

		@include media-breakpoint-up(md){
			margin:3rem 0;
		}

		li{
			counter-increment: listcount;
			position: relative;
			padding-left: 35px;
			margin: 0 0 2rem;

			@include media-breakpoint-up(md){
				padding-left: 50px;
				margin: 0 0 2.5rem;
			}

			&:before{
				content: counter(listcount) '.';
				font-size: 3rem;
				color:$primary;
				font-family: $headings-font;
				position: absolute;
				left:0;
				top:5px;

				@include media-breakpoint-up(md){
					top:10px;
					font-size: 5rem;
				}
			}

			small{
				display: block;
				font-size: 11px;
				margin: 10px 0 ;
			}
		}
	}

	.btn-section{
		margin: 0 auto;
	}
}